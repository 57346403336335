<template>
    <div class="sticky-button">
        <a href="#work-together-cont" title="Subscribe" @click.prevent="scrollToSection">
            <div class="sr-only">Subscribe</div>
            <img src="../assets/sticky-subscribe-img.svg" alt="Subscribe">
        </a>
    </div>
</template>

<script>
export default {
  methods: {
    scrollToSection() {
      const section = document.querySelector('#work-together-cont');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
}
</script>

<style>
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border-width: 0;
}
.sticky-button {
    position: fixed;
    bottom: 0.2vw;
    right: 1vw;
    max-width: 8vw;
    z-index: +6;
    animation: float 2.5s ease-in-out infinite;
}
.sticky-button img {
    filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.1));
    cursor: pointer;
    object-fit: contain;
    max-width: 8vw;
    height: auto;
}
body[style*="overflow: hidden"] .sticky-button {
    z-index: +1;
}
@media (max-aspect-ratio: 200/200) {
    .sticky-button {
        max-width: 18vw;
    }
    .sticky-button img {
        max-width: 18vw;
    }
}
@keyframes float {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-0.8vw);
    }
    100% {
        transform: translatey(0px);
    }
}
</style>